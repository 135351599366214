import { User } from "hooks/useAuthProvider/types";
import { ProtectionProps } from "hooks/useCart/types";
import { StateAddedPayments } from "hooks/useCheckout/types";
import { CartItems, SimpleCartProps, Coordinate } from "services/cart/types";
import { EventsTicketsData } from "services/eventTicket/types";

export enum GatewayType {
  mercadoPago = 'MERCADO_PAGO',
  MP_Pacaembu = 'MP_Pacaembu',
}
export enum GatewayCheckout {
  NotSet,
  MercadoPago,
  Malga,
  PagSeguro,
  PicPay,
  MPPacaembu,
  Main,
}
export enum PaymentTypeCheckout {
  NotSet,
  Money,
  Card,
  Pix,
  FreeTicket,
  Mixed,
  Online, // usar para checkoutpro
  PostPaid,
  Credit,
  Debit,
}
export interface PayloadCheckout {
  client: {
    id: number
    name: string
    email: string
  }
  paymentMethodInfo: {
    pixInfo: {
      email: string
      firstName: string
      lastName: string
      type: string
      value: string
    }
  }
  cartItems: {
    id: number
    quantity: number
    noInterest: number
    eventId: number
    eventName: string
    sectionType: number
    taxTotal: number
    taxAppTotal: number
    ticketName: string
    lotId: number
    ticketId: number
    ticketLotId: number
    ticketLotValue: number
    ticketValue: number
    lotTax: number
    lotProcessingFeeAdd?: number
    lotProcessingFeeValue?: number
    coordinates?: Coordinate[]
    cpfs?: string[]
  }[]
  paymentType: PaymentTypeCheckout
  gateway: GatewayCheckout
  nfeDocument?: string | null
  appVersion?: string | null
  seatsHoldToken?: string
  franchiseId: number
  sellerId?: string
  preferenceId?: string
  sessionId?: string
  protection: {
    quoteId: string
    value: number
  }
}
export interface ImpPix {
  client: User
  franchiseId: number
  cartItems: CartItems[]
  gateway?: GatewayType
  document: string
  typeDocument: string
  email: string
  firstName: string
  lastName: string
  protection: ProtectionProps
  documentNFCE?: string
  preferenceId?: string
  SeatsHoldToken?: string
}

export interface ICreatePixForMultiplePayment extends ImpPix {
  multiplePaymentUid?: string;
  isMultiplePayment: boolean;
  multiplePaymentValue: number;
  totalTicketValue: number;
  paymentGateway: PaymentGateway;
}

export interface GeneratePixType {
  OrderUID: string;
  QrCodeBase64: string;
  QrCodePlainText: string;
  MercadoPago: {};
  paymentId: string;
  status: string;
}

export interface ICard {
  cardName: string;
  cardNumber: string;
  cardOwnerCpf?: string;
  installments: string;
  gateway?: string;
  paymentType?: string;
  cartItems?: CartItems[];
  client?: User;
  sellerId?: String;
  isComissioner?: boolean;
  issuerId?: String;
  paymentMethodId?: number;
  preferenceId?: string;
  NFEDocument?: string | null;
  auth3dsId?: string;
  franchiseId?: number;
  installmentValue: string;
  installmentFee?: string;
  cardToken?: string;
  nfeDocument?: string | null;
  sessionId?: String | null;
  SeatsHoldToken?: string;
  protection?: ProtectionProps;
  deviceId?: string;
}

export interface ICardResponse {
  status: string,
  message: string,
  transactionUID: string,
  paymentStatus: number,
  paymentId: string,
  threeDSecureModeInfo: {
    ExternalResourceUrl: string
    Creq: string
  }
}

export interface IPaymentStatusCreditCardResponse {
  id: number
  status: string
  statusDetail: string
  message: string
}

export interface IPaymentsDetail {
  cardHolderName: string;
  cardNumber: string;
  installments: number;
  partialOrderValue: number;
  paymentValue: number;
  status: number;
  totalOrderValue: number;
  paymentCode: string;
}

export interface IPendingMultipleTransactionResponse {
  Result: {
    cart: {
      cart: SimpleCartProps;
      eventId: number;
      exp: string;
      sessionID: string;
      status: string;
    };
    eventData: EventsTicketsData;
    paymentsDetail: IPaymentsDetail[];
    multiplePaymentUID: string;
  }[]
}

export interface IInstallment {
  Installments: number
  TotalValue: number
  InstallmentValue: number
  InstallmentFee: number
  Alias: string
}

export interface IGetPublicKey {
  franchiseId: number
  isNoInterest: number
  paymentGateway: PaymentGateway
}

export interface ICheckoutPro {
  cardOwnerCpf: string;
  checkoutProType: CheckoutPro;
  cartItems: CartItems[];
  client: User;
  paymentGateway: PaymentGateway;
  franchiseId: number;
  sellerId: string;
  isComissioner: boolean;
  nfeDocument: string;
  seatsHoldToken?: string;
  protection: ProtectionProps;
}

export enum CheckoutPro {
  WALLET_BRICKS = 0,
  CHECKOUT_PRO = 1,
}

export enum PaymentGateway {
  PagSeguro = 0,
  MercadoPago = 1,
  Multiadquirente = 3,
  MP_Pacaembu = 4,
  MP_MultiplePaymentMethod = 5
}

export const PaymentGatewayString = {
  '2': () => {
    return 'MERCADO_PAGO'
  },
  '4': () => {
    return 'MP_Pacaembu'
  },
}

export enum PaymentStatus {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
  AUTHORIZED = "authorized",
  IN_PROCESS = "in_process",
  IN_MEDIATION = "in_mediation",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
  CHARGED_BACK = "charged_back"
}

export interface ICreditCardRequest extends ICard {
  isMultiplePayment: boolean;
  multiplePaymentUid?: string;
  partialPaymentValue: number;
  totalTicketValue?: number;
  paymentGateway?: PaymentGateway;
}

export interface IPartialTransactionResponse extends ICardResponse {
  status: StateAddedPayments;
}

export interface ICheckoutProResponse {
  transactionUID: string;
  preferenceId: string;
  initUrl: string;
}

export interface IReimburseMultiplePaymentsRequest {
  transactionUID: string;
}

export interface IReimburseMultiplePaymentsResponse {
  data: string[];
  success: boolean;
  errorMessage: string;
}

export interface IPaymentGatewayTransactionRequest {
  paymentId: string;
}

export enum PaymentGatewayTransactionStatus {
  "pending",
  "approved",
  "disapproved",
  "reimbursed_pending",
  "reimbursed_fail",
  "reimbursed"
}

export interface IPaymentGatewayTransactionResponse {
  paymentCode: string;
  uid: string;
  gateway: string;
  status: PaymentGatewayTransactionStatus;
  paymentValue: number;
  totalOrderValue: number;
  partialOrderValue: number;
  noInterest: boolean;
  franchiseId: number;
  id: string;
}
