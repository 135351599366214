import { REACT_APP_CDN_IMG } from "constant";

export const cartImg = `${REACT_APP_CDN_IMG}/img/website-images/cart.svg`;
export const loginImg = `${REACT_APP_CDN_IMG}/img/website-images/login.svg`;
export const logoutImg = `${REACT_APP_CDN_IMG}/img/website-images/logout.svg`;
export const userImg = `${REACT_APP_CDN_IMG}/img/website-images/user.png`;
export const userProfileImg = `${REACT_APP_CDN_IMG}/img/website-images/userProfile.svg`;
export const emailProfileImg = `${REACT_APP_CDN_IMG}/img/website-images/emailProfile.svg`;
export const phoneProfileImg = `${REACT_APP_CDN_IMG}/img/website-images/phoneProfile.svg`;
export const editProfileImg = `${REACT_APP_CDN_IMG}/img/website-images/edit.svg`;
export const closeMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/close.svg`;
export const eventProfileImg = `${REACT_APP_CDN_IMG}/img/website-images/evento-img.jpeg`;
export const notificationImg = `${REACT_APP_CDN_IMG}/img/website-images/notification.svg`;
export const menuImg = `${REACT_APP_CDN_IMG}/img/website-images/menu.svg`;
export const arrowLeftImg = `${REACT_APP_CDN_IMG}/img/website-images/arrow-left.svg`;
export const ticketsMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/tickets-mobile.svg`;
export const cartMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/cart-moblie.svg`;
export const carrotDown = `${REACT_APP_CDN_IMG}/img/website-images/carrotDown.svg`;
export const calendarMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/calendar-mobile.svg`;
export const starMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/star-mobile.svg`;
export const arrowMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/arrow-mobile.svg`;
export const logoutMobileImg = `${REACT_APP_CDN_IMG}/img/website-images/logout-mobile.svg`;
export const searchImg = `${REACT_APP_CDN_IMG}/img/website-images/search.svg`;
export const clockImg = `${REACT_APP_CDN_IMG}/img/website-images/clock.svg`;
export const clockBlackImg = `${REACT_APP_CDN_IMG}/img/website-images/clock-black.svg`;
export const placeBlackImg = `${REACT_APP_CDN_IMG}/img/website-images/place-black.svg`;
export const placeImg = `${REACT_APP_CDN_IMG}/img/website-images/place.svg`;
export const pointImg = `${REACT_APP_CDN_IMG}/img/website-images/point.svg`;
export const phonePointImg = `${REACT_APP_CDN_IMG}/img/website-images/phone-point.svg`;
export const emailImg = `${REACT_APP_CDN_IMG}/img/website-images/email.svg`;
export const emailErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/email-error.svg`;
export const lockImg = `${REACT_APP_CDN_IMG}/img/website-images/lock.svg`;
export const lockErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/lock-error.svg`;
export const eyesClodedImg = `${REACT_APP_CDN_IMG}/img/website-images/eyes-closed.svg`;
export const eyesOpenImg = `${REACT_APP_CDN_IMG}/img/website-images/eyes-open.svg`;
export const googleLoginImg = `${REACT_APP_CDN_IMG}/img/website-images/googleLogin.svg`;
export const facebookLoginImg = `${REACT_APP_CDN_IMG}/img/website-images/facebookLogin.svg`;
export const appleLoginImg = `${REACT_APP_CDN_IMG}/img/website-images/appleLogin.svg`;
export const userRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/userRegister.svg`;
export const userRegisterErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/user-error.svg`;
export const phoneRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/phoneRegister.svg`;
export const phoneRegisterErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/phoneRegister-error.svg`;
export const fingerprintRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/fingerprintRegister.svg`;
export const fingerprintRegisterErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/fingerprintRegister-error.svg`;
export const calendarRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/calendarRegister.svg`;
export const calendarRegisterErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/calendarRegister-error.svg`;
export const placeRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/placeRegister.svg`;
export const placeRegisterErrorImg = `${REACT_APP_CDN_IMG}/img/website-images/placeRegister-error.svg`;
export const chevronRegisterImg = `${REACT_APP_CDN_IMG}/img/website-images/chevronRegister.svg`;
export const warningImg = `${REACT_APP_CDN_IMG}/img/website-images/warning.svg`;
export const warningTransaction = `${REACT_APP_CDN_IMG}/img/website-images/warningTransaction.svg`;
export const closeModalImg = `${REACT_APP_CDN_IMG}/img/website-images/closeModal.svg`;
export const warningLightImg = `${REACT_APP_CDN_IMG}/img/website-images/warning-data-user.svg`;
export const arrowReturnImg = `${REACT_APP_CDN_IMG}/img/website-images/arrow-return.svg`;
export const minusImg = `${REACT_APP_CDN_IMG}/img/website-images/minus.svg`;
export const plusImg = `${REACT_APP_CDN_IMG}/img/website-images/plus.svg`;
export const eventoImg = `${REACT_APP_CDN_IMG}/img/website-images/evento-img.jpeg`;
export const cartSummaryImg = `${REACT_APP_CDN_IMG}/img/website-images/cart-summary.svg`;
export const couponImg = `${REACT_APP_CDN_IMG}/img/website-images/coupon.svg`;
export const trashImg = `${REACT_APP_CDN_IMG}/img/website-images/trash.svg`;
export const trash2Img = `${REACT_APP_CDN_IMG}/img/website-images/trash-2.svg`;
export const trashBlueImg = `${REACT_APP_CDN_IMG}/img/website-images/trashBlue.svg`;
export const creditDisabledImg = `${REACT_APP_CDN_IMG}/img/website-images/credit-disabled.svg`;
export const pixDisabledImg = `${REACT_APP_CDN_IMG}/img/website-images/pix-disabled.svg`;
export const creditActiveImg = `${REACT_APP_CDN_IMG}/img/website-images/credit-active.svg`;
export const pixActiveImg = `${REACT_APP_CDN_IMG}/img/website-images/pix-active.svg`;
export const pixGreen = `${REACT_APP_CDN_IMG}/img/website-images/pix-green.png`;
export const creditInputImg = `${REACT_APP_CDN_IMG}/img/website-images/credit-card-input.svg`;
export const chevronPaymentImg = `${REACT_APP_CDN_IMG}/img/website-images/chevron-payment.svg`;
export const copyImg = `${REACT_APP_CDN_IMG}/img/website-images/copy.svg`;
export const sendPaymentsImg = `${REACT_APP_CDN_IMG}/img/website-images/send-payments.svg`;
export const downloadImg = `${REACT_APP_CDN_IMG}/img/website-images/download.svg`;
export const googleMapsImg = `${REACT_APP_CDN_IMG}/img/website-images/google-maps.svg`;
export const uberImg = `${REACT_APP_CDN_IMG}/img/website-images/uber.svg`;
export const whatsButtonImg = `${REACT_APP_CDN_IMG}/img/website-images/whats.svg`;
export const q2bankImg = `${REACT_APP_CDN_IMG}/img/website-images/q2bank-logo.svg`;
export const smartphoneImg = `${REACT_APP_CDN_IMG}/img/website-images/smartphone.png`;
export const uberButtonImg = `${REACT_APP_CDN_IMG}/img/website-images/uberButton.svg`;
export const iconPopupClose = `${REACT_APP_CDN_IMG}/img/website-images/popupClose.svg`;
export const landingContinue = `${REACT_APP_CDN_IMG}/img/website-images/landingContinue.png`;
export const landingLeft = `${REACT_APP_CDN_IMG}/img/website-images/landingLeft.svg`;
export const landingCheck = `${REACT_APP_CDN_IMG}/img/website-images/landingCheck.svg`;
export const landingBarSystem = `${REACT_APP_CDN_IMG}/img/website-images/landingBarSystem.png`;
export const landingGrafica = `${REACT_APP_CDN_IMG}/img/website-images/landingGrafica.png`;
export const landingMarketing = `${REACT_APP_CDN_IMG}/img/website-images/landingMarketing.png`;
export const landingTechnology = `${REACT_APP_CDN_IMG}/img/website-images/landingTechnology.png`;
export const elipsesGroup = `${REACT_APP_CDN_IMG}/img/website-images/elipsesGroup.svg`;
export const Person = `${REACT_APP_CDN_IMG}/img/website-images/person.svg`;
export const landingClient1 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient1.png`;
export const landingClient2 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient2.png`;
export const landingClient3 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient3.png`;
export const landingClient4 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient4.png`;
export const landingClient5 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient5.png`;
export const landingClient6 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient6.png`;
export const landingClient7 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient7.png`;
export const landingClient8 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient8.png`;
export const landingClient9 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient9.png`;
export const landingClient10 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient10.png`;
export const landingClient11 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient11.png`;
export const landingClient12 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient12.png`;
export const landingClient13 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient13.png`;
export const landingClient14 = `${REACT_APP_CDN_IMG}/img/website-images/landingClient14.png`;
export const landingLast2 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast2.png`;
export const landingLast3 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast3.png`;
export const landingLast4 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast4.png`;
export const landingLast5 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast5.png`;
export const landingLast6 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast6.png`;
export const landingLast7 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast7.jpg`;
export const landingLast8 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast8.jpg`;
export const landingLast9 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast9.jpg`;
export const landingLast10 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast10.jpg`;
export const landingLast11 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast11.jpg`;
export const landingLast12 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast12.jpg`;
export const landingLast13 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast13.jpg`;
export const landingLast14 = `${REACT_APP_CDN_IMG}/img/website-images/landingLast14.jpg`;
export const chevronRight = `${REACT_APP_CDN_IMG}/img/website-images/chevronRight.svg`;
export const LeftArrowLpBlack = `${REACT_APP_CDN_IMG}/img/website-images/leftArrowLpBlack.svg`;
export const chevronDown = `${REACT_APP_CDN_IMG}/img/website-images/chevronDown.svg`;
export const satisfacao1 = `${REACT_APP_CDN_IMG}/img/website-images/satisfacao1.png`;
export const satisfacao2 = `${REACT_APP_CDN_IMG}/img/website-images/satisfacao2.png`;
export const satisfacao3 = `${REACT_APP_CDN_IMG}/img/website-images/satisfacao3.png`;
export const LpAcesso = `${REACT_APP_CDN_IMG}/img/website-images/LpAcesso.png`;
export const LpComissario = `${REACT_APP_CDN_IMG}/img/website-images/LpComissario.png`;
export const LpCompleto = `${REACT_APP_CDN_IMG}/img/website-images/LpCompleto.png`;
export const LpOnline = `${REACT_APP_CDN_IMG}/img/website-images/LpOnline.png`;
export const LpVendas = `${REACT_APP_CDN_IMG}/img/website-images/LpVendas.png`;
export const LpTotem = `${REACT_APP_CDN_IMG}/img/website-images/LpTotem.png`;
export const SiteSeguroImg2 = `${REACT_APP_CDN_IMG}/img/website-images/siteSeguroImg2.png`;
export const AssociadoAbrape2 = `${REACT_APP_CDN_IMG}/img/website-images/associadoAbrape2.png`;
export const cartClock = `${REACT_APP_CDN_IMG}/img/website-images/cartClock.svg`;
export const StrongCheck = `${REACT_APP_CDN_IMG}/img/website-images/strongCheck.svg`;
export const Bullet = `${REACT_APP_CDN_IMG}/img/website-images/bullet.svg`;
export const YoutubeIcon = `${REACT_APP_CDN_IMG}/img/website-images/youtube.svg`;
export const FacebookIcon = `${REACT_APP_CDN_IMG}/img/website-images/facebook.svg`;
export const InstagramIcon = `${REACT_APP_CDN_IMG}/img/website-images/instagram.svg`;
export const ProcessAnimation1 = `${REACT_APP_CDN_IMG}/img/website-images/ProcessAnimation/1.svg`;
export const ProcessAnimation2 = `${REACT_APP_CDN_IMG}/img/website-images/ProcessAnimation/2.svg`;
export const ProcessAnimation3 = `${REACT_APP_CDN_IMG}/img/website-images/ProcessAnimation/3.svg`;
export const SeloIndicadaHorizontal = `${REACT_APP_CDN_IMG}/img/website-images/seloIndicadaHorizontal.svg`;
export const SSL = `${REACT_APP_CDN_IMG}/img/website-images/SSL.png`;
export const PromoIconImg = `${process.env.REACT_APP_CDN_IMG}/img/website-images/PromoIconTicket.svg?cache=2`;
export const logoImg = `${REACT_APP_CDN_IMG}/img/website-images/q2logo.gif`;
export const YoutubeBlueIcon = `${REACT_APP_CDN_IMG}/img/website-images/youtubeBlue.svg`;
export const FacebookBlueIcon = `${REACT_APP_CDN_IMG}/img/website-images/facebookBlue.svg`;
export const InstagramBlueIcon = `${REACT_APP_CDN_IMG}/img/website-images/instagramBlue.svg`;
export const infoBlueIcon = `${REACT_APP_CDN_IMG}/img/website-images/infoBlue.svg`;
export const BrandsIcon = `${REACT_APP_CDN_IMG}/img/website-images/logos.svg`;
export const NewQ2BankIcon = `${REACT_APP_CDN_IMG}/img/website-images/q2banklogo.svg`;
export const AppStoreIcon = `${REACT_APP_CDN_IMG}/img/website-images/app-store-logo.svg`;
export const GooglePlayIcon = `${REACT_APP_CDN_IMG}/img/website-images/google-play-logo.svg`;
export const AbrapeImage = `${REACT_APP_CDN_IMG}/filters:format(webp):quality(70)/img/website-images/associado-abrape.png`;
export const ComplianceImage = `${REACT_APP_CDN_IMG}/filters:format(webp):quality(70)/img/website-images/compliance.png`;
export const LgpdImage = `${REACT_APP_CDN_IMG}/filters:format(webp):quality(70)/img/website-images/lgpd.png`;
export const VotingReclameAqui = `${REACT_APP_CDN_IMG}/filters:format(webp):quality(70)/img/website-images/votacao-reclameaqui-Q2.svg`;
export const ArrowDropDown = `${REACT_APP_CDN_IMG}/img/website-images/arrow_drop_down.svg`;
export const MercadoLivreIcon = `${REACT_APP_CDN_IMG}/img/website-images/logo_mercado_livre.svg`;
export const CreditCardIcon = `${REACT_APP_CDN_IMG}/img/website-images/black_credit_card.svg`;
export const PixIcon = `${REACT_APP_CDN_IMG}/img/website-images/black_pix.svg`;
export const ArrowUpBlueIcon = `${REACT_APP_CDN_IMG}/img/website-images/arrowUpBlue.svg`;
export const AlertCircle = `${REACT_APP_CDN_IMG}/img/website-images/alert-circle.svg`;
export const infoWhiteIcon = `${REACT_APP_CDN_IMG}/img/website-images/infoWhite.svg.svg`;
export const whatsAppWhiteIcon = `${REACT_APP_CDN_IMG}/img/website-images/whatsAppWhite.svg`;
export const checkBoxBlueOn = `${REACT_APP_CDN_IMG}/img/website-images/CheckBoxBlueOn.svg`;
export const refundCheckBoxOn = `${REACT_APP_CDN_IMG}/img/website-images/refundCheckBoxOn.svg`;
export const refundCheckBoxOff = `${REACT_APP_CDN_IMG}/img/website-images/refundCheckBoxOff.svg`;
